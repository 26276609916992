export const siteData = {
  "name": "MASSIOL",
  "address": "12 rue de la paix",
  "postCode": "75008",
  "city": "paris",
  "phone": "0287719697",
  "url": "https://massiol.com",
  "urlSub": "massiol.com",
  "price": "price_aAwZXBt1vWTKG4Mpo5jebv8P2R0s9Xfv",
  "pk": "pk_live_pwh5kpkdL10VASSK3ivZmX23pUlzM9qC",
  "sk": "sk_live_D9YiyDhks8NUYUOTOo89t0CvesO3jdD6"
};